import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { AnalyticGroupData } from '@bazis/analytics/shared/models/analytic.types';

@Component({
    selector: 'bazis-analytics-vertical-bars',
    templateUrl: './vertical-bars.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisVerticalBarsComponent implements OnInit {
    @Input()
    groupData: AnalyticGroupData[];

    @Input()
    selectedValues: string[];

    @Output()
    select = new EventEmitter();

    get maxForHeight() {
        return Math.max(...this.groupData.map((v) => v.value)) * 1.1;
    }

    constructor() {}

    ngOnInit(): void {}
}
