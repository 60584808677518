import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, shareReplay } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { MaskSettings } from '@bazis/form/models/form-element.types';
import { convertMbToBytes, latinizeStr } from '@bazis/utils';

export const BASIC_DATETIME_LOCALE = {
    shortDate: 'dd.MM.y',
    shortDateShortWeekDay: 'dd.MM.y, EEEEEE',
    shortDateMediumWeekDay: 'dd.MM.y, EEE',
    shortDateLongWeekDay: 'dd.MM.y, EEEE',
    shortDateTime: 'dd.MM.y, HH:mm',
    shortDateMonth: 'dd.MM',
    shortDateMonthTime: 'dd.MM, HH:mm',
    shortTimeDate: 'HH:mm, dd.MM.y',
    time: 'HH:mm',
    mediumDate: 'dd MMM y',
    mediumDateShortWeekDay: 'dd MMM y, EEEEEE',
    mediumDateTime: 'dd MMM y, HH:mm',
    mediumCurrentDateTimeSeconds: 'dd MMM HH:mm:ss',
    mediumTimeDate: 'HH:mm, dd MMM y',
    mediumDateMonth: 'dd MMM',
    longDate: 'dd MMMM y',
    longDateTime: 'dd MMMM y, HH:mm',
    longDateShortWeekDayTime: 'dd MMMM y, EEEEEE, HH:mm',
    longTimeDate: 'HH:mm, dd MMMM y',
    longDateMonthShortWeekDay: 'dd MMMM, EEEEEE',
    longDateMonthYearShortWeekDay: 'dd MMMM y, EEEEEE',
    longDateMonth: 'd MMMM',
    longMonthYear: 'MMMM y',
    year: 'y',
    month: 'MM',
    mediumMonth: 'MMM',
    mediumMonthStandalone: 'LLL',
    longMonthYearStandalone: 'LLLL y',
    longMonth: 'LLLL',
    day: 'd',
};

export const DATETIME_LOCALE = {
    ru: { ...BASIC_DATETIME_LOCALE },
    en: {
        ...BASIC_DATETIME_LOCALE,
        mediumDate: 'MMM dd, y',
        mediumDateShortWeekDay: 'EEEEEE, MMM dd, y',
        mediumDateTime: 'MMM dd, y, HH:mm',
        mediumCurrentDateTimeSeconds: 'MMM dd, HH:mm:ss',
        mediumTimeDate: 'HH:mm, MMM dd, y',
        mediumDateMonth: 'MMM, dd',
        longDate: 'MMMM dd, y',
        longDateTime: 'MMMM dd, y, HH:mm',
        longDateShortWeekDayTime: 'EEEEEE, MMMM dd, y, HH:mm',
        longTimeDate: 'HH:mm, MMMM dd, y',
        longDateMonthShortWeekDay: 'EEEEEE, MMMM dd',
        longDateMonthYearShortWeekDay: 'EEEEEE, MMMM dd, y',
        longDateMonth: 'MMMM d',
        longMonthYear: 'MMMM, y',
    },
};

export const PHONE_MASK = '+7 999 999-99-99';
export const PHONE_PATTERN = /\+7[0-9]{10}/;
export const FOREIGN_PHONE_PATTERN = /\+[0-9]{9,15}$/;

export const VEHICLE_MASK_SETTINGS: Partial<MaskSettings> = {
    placeHolderCharacter: '_',
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
    patterns: {
        V: {
            pattern: new RegExp('^[abekmhopctyxавекмнорстухABEKMHOPCTYXАВЕКМНОРСТУХ]'),
        },
        '0': {
            pattern: new RegExp('\\d'),
        },
    },
    outputTransformFn: (value) => latinizeStr(`${value}`.toUpperCase()),
    inputTransformFn: (value) => latinizeStr(`${value}`.toUpperCase()),
};
export const PHONE_MASK_SETTINGS: Partial<MaskSettings> = {
    pipeMask: '+7 000 000-00-00',
    mask: '000 000-00-00',
    prefix: '+7 ',
    placeHolderCharacter: '_',
    dropSpecialCharacters: [' ', '-'],
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
    outputTransformFn: (value) => {
        return value ? `+7${value}` : '';
    },
    // inputTransformFn: (value: string) =>
    //     value.indexOf('+7') > -1 ? value.split('+7').join('') : value,
};

export const PHONE_INPUT_TRANSFORM_FN = (phone: string) =>
    phone && phone.indexOf('+7') > -1 ? phone.split('+7').join('') : phone;

export const FOREIGN_PHONE_MASK_SETTINGS: Partial<MaskSettings> = {
    pipeMask: '+000000000999999',
    mask: '000000000999999',
    prefix: '+',
    placeHolderCharacter: '',
    dropSpecialCharacters: [],
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
    patterns: {
        '9': {
            pattern: new RegExp('\\d'),
            optional: true,
        },
        '0': {
            pattern: new RegExp('\\d'),
            optional: false,
        },
    },
    outputTransformFn: (value) => {
        return value ? `+${value}` : '';
    },
};

export const FOREIGN_PHONE_INPUT_TRANSFORM_FN = (phone: string) =>
    phone && phone.indexOf('+') > -1 ? phone.split('+').join('') : phone;

export const COMMON_DATE_MASK_SETTINGS = {
    // keepCharacterPositions: false,
    placeHolderCharacter: '_',
    showMaskTyped: true,
    dropSpecialCharacters: [],
    // clearIfNotMatch: false,
    // validation: false,
};

export const SNILS_PATTERN = /[0-9]{11}/;
export const SNILS_MASK_SETTINGS = {
    mask: '000-000-000 00',
    keepCharacterPositions: false,
    placeHolderCharacter: '_',
    clearIfNotMatch: false,
    validation: false,
    dropSpecialCharacters: [' ', '-'],
};

export const BIC_PATTERN = /[0-9]{9}/;
export const RRC_PATTERN = /[0-9]{9}/;
export const INN_PATTERN = /^([0-9]{10}|[0-9]{12})$/;
export const CORRESPONDENT_ACCOUNT_PATTERN = /^[0-9]{20}$/;
export const BANK_ACCOUNT_PATTERN = /^4[0-9]{4}810[0-9]{12}$/;
export const OKTMO_MASK_SETTINGS = {
    mask: '00000000000',
    showMaskTyped: false,
    clearIfNotMatch: false,
    validation: true,
};
export const DEFAULT_LINK_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'subset',
    fragment: 'exact',
    paths: 'subset',
};

export const SHARE_REPLAY_SETTINGS = { bufferSize: 1, refCount: true };

export const API_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZ';
export const API_DATETIME_FORMAT_Z = 'YYYY-MM-DD[T]HH:mm:ss[Z]';

export const MAX_FILE_SIZE = convertMbToBytes(10); // 10мб
export const DEFAULT_LIST_LIMIT = 20;
export const ALL_LIST_LIMIT = 1000;

export const API_DOC_LANGS_MAP = {
    ru: 'ru',
    en: 'en-us',
};

export const FORM_SAVE_BUTTON_SETTINGS = {
    titleKey: 'action.save',
};

export const UNDERCONSTRUCTION_TITLE = {
    feature: 'title.featureUnderconstruction',
    section: 'title.sectionUnderconstruction',
};

export const DOCUMENT_LINKS = {
    privacyPolicy: '/legal-documents/privacy_policy',
    userAgreement: '/legal-documents/site_rules',
    cookiePolicy: '/legal-documents/cookie_policy',
    agencyContract: '/legal-documents/agency_contract',
    partnerContract: '/legal-documents/partner_contract',
    buyerContract: '/legal-documents/sales_agency_contract',
};

export const COLORS = {
    info: '#C4C4C4',
    success: '#78C649',
    warning: '#F6BF36',
    danger: '#ED1A34',
    light: '#f4f5f8',
    medium: '#92949c',
    dark: '#111214',
};

@Injectable({
    providedIn: 'root',
})
export class BazisConfigurationService {
    protected configuration: any = {
        tiles: {
            default: {
                url: 'https://{s}.map.asu.big3.ru/street/{z}/{x}/{y}.png',
                size: 256,
            },
        },
        emptyInputValue: '-',
        defaultLocation: [55.75222, 37.61556],
        contacts: { email: '', phone: '' },
        vehicleNumberMask: ['V', '000', 'VV', '000'],
        breadcrumbsIconSeparator: 'angle-small-right',
    };

    protected settingsUrl = '';

    selectedLanguage = 'ru';

    availableLanguages = ['ru'];

    constructor(
        protected http: HttpClient,
        protected router: Router,
        protected srvService: BazisSrvService,
    ) {}

    public loadConfiguration(): Observable<any> {
        document.documentElement.setAttribute('lang', this.selectedLanguage);

        const apiSettings$ = this.settingsUrl
            ? this.srvService.commonGetRequest$(this.settingsUrl)
            : of({});
        return combineLatest([this.http.get('/api/schemas.json'), apiSettings$]).pipe(
            tap(([schemas, apiConfig]) => {
                if (!schemas) throw new Error('emptySchema');
                this.configuration = {
                    ...this.configuration,
                    ...apiConfig,
                    schemas: this._processSchemas(schemas),
                };
            }),
            catchError((e) => {
                this.router.navigateByUrl('/critical-error');
                return of(null);
            }),
        );
    }

    protected _generateFrontSchemaKey = (key) => {
        const parts = key.split('__');
        let schemaPartIndex = parts.findIndex((part) => part.indexOf('schema') > -1);
        if (schemaPartIndex === -1) schemaPartIndex = parts.length + 1;
        return parts.slice(0, schemaPartIndex + 1).join('__');
    };

    protected _processSchemas(schemas) {
        const frontSchemas = {};
        Object.keys(schemas).forEach((schemaKey) => {
            const frontSchemaKey = this._generateFrontSchemaKey(schemaKey);

            if (
                schemas[schemaKey].properties?.data?.allOf ||
                schemas[schemaKey].properties?.data?.items
            ) {
                const props =
                    schemas[schemaKey].properties?.data?.items ||
                    schemas[schemaKey].properties?.data?.allOf[0];
                frontSchemas[frontSchemaKey] = {};
                const attrs = props.properties.attributes.allOf;
                const relationships = props.properties.relationships.allOf;
                if (attrs && attrs[0]) {
                    frontSchemas[frontSchemaKey].attributes = attrs[0].properties;
                }
                if (relationships && relationships[0]) {
                    frontSchemas[frontSchemaKey].relationships = relationships[0].properties;
                    for (let key in frontSchemas[frontSchemaKey].relationships) {
                        const data =
                            frontSchemas[frontSchemaKey].relationships[key].allOf[0].properties
                                .data;
                        const items = data.allOf || [data.items];
                        frontSchemas[frontSchemaKey].relationships[key].entityType =
                            items[0].properties.type.default;
                        frontSchemas[frontSchemaKey].relationships[key].type = data.items
                            ? 'array'
                            : 'object';
                    }
                }
            } else {
                frontSchemas[frontSchemaKey] = schemas[schemaKey];
            }
        });
        // console.log('Common schemas in front format', frontSchemas);
        return frontSchemas;
    }

    get schemas(): any {
        return this.configuration?.schemas || null;
    }

    get tiles(): any {
        return this.configuration?.tiles || null;
    }

    get mapDefaultSettings(): any {
        return this.getMapSettings('default');
    }

    get emptyInputValue(): any {
        return this.configuration?.emptyInputValue || null;
    }

    get contacts(): any {
        return this.configuration?.contacts || null;
    }

    get vehicleNumberMask(): any {
        return this.configuration?.vehicleNumberMask || null;
    }

    get defaultLocation(): any {
        return this.configuration?.defaultLocation || null;
    }

    get chatMessageEditDelay(): any {
        return (this.configuration?.CHAT_MESSAGE_EDIT_DELAY || 0) * 1000;
    }

    get signatureType(): 'crypto' | 'docusign' {
        return 'crypto';
    }

    get breadcrumbsIconSeparator(): string {
        if (this.configuration?.breadcrumbsIconSeparator === undefined) return 'angle-small-right';
        return this.configuration?.breadcrumbsIconSeparator;
    }

    getConfigField(fieldName) {
        return this.configuration[fieldName];
    }

    getMapSettings(tileType = 'default') {
        if (!this.configuration?.tiles) return null;
        return {
            tile: this.configuration.tiles[tileType],
        };
    }

    getMapMultipleTilesSettings(
        tiles = [],
        defaultTileKey = '',
    ): { tiles: { id: string; nameKey: string; tile: any; isDefault: boolean }[] } {
        if (!this.configuration?.tiles) return null;
        tiles = !tiles.length ? Object.keys(this.configuration?.tiles) : tiles;
        defaultTileKey = defaultTileKey || tiles[0];

        return {
            tiles: tiles.reduce((acc, tileKey) => {
                acc.push({
                    id: tileKey,
                    nameKey: `map.tiles.${tileKey}`,
                    tile: this.configuration.tiles[tileKey],
                    isDefault: defaultTileKey === tileKey,
                });
                return acc;
            }, []),
        };
    }

    getEnumOptionList(entityType, field) {
        const serviceTypeEnumDict =
            this.schemas[`${entityType.replace('.', '__')}__schema_retrieve`].attributes[field]
                .enumDict;
        return Object.keys(serviceTypeEnumDict).reduce((acc, current) => {
            acc.push({
                id: current,
                name: serviceTypeEnumDict[current],
            });
            return acc;
        }, []);
    }

    getSchemaByEntityType(entityType, schemaType = 'schema_retrieve') {
        return this.schemas[`${entityType.replace('.', '__')}__schema_retrieve`];
    }
}
