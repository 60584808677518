import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { AnalyticGroupData } from '@bazis/analytics/shared/models/analytic.types';

@Component({
    selector: 'app-analytics-summary-table',
    templateUrl: './summary-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsSummaryTableComponent implements OnInit {
    @Input()
    settings: any;

    @Input()
    groupData: AnalyticGroupData[];

    @Input()
    selectedValues: string[];

    @Output()
    select = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
