<div
    class="sl-card-mobile"
    [class.sl-card-mobile--expanded]="isExpanded"
>
    <div class="sl-card-mobile__header">
        <ng-content select="header-selection"> </ng-content>
        <bazis-toolbar [align]="'top'">
            <div slot="start">
                <ng-content select="header-start"></ng-content>
            </div>
            <div slot="end">
                <ng-content select="header-end"></ng-content>
                <div
                    *ngIf="hasActions"
                    class="bazis-dropdown sl-dropdown_action"
                    clickOutside
                    (clickOutside)="hideDropdown($event)"
                    [excludeElement]="toggleBtn"
                >
                    <bazis-button
                        #toggleBtn
                        size="small"
                        fill="clear"
                        class="bazis-dropdown__trigger"
                        (click)="showDropdown($event)"
                    >
                        <bazis-icon
                            slot="icon-only"
                            name="menu-dots"
                            size="s"
                        ></bazis-icon>
                    </bazis-button>

                    <div
                        *ngIf="isShowActions"
                        class="bazis-dropdown__body"
                    >
                        <ng-content select="list-actions"></ng-content>
                    </div>
                </div>
                <bazis-button
                    size="small"
                    fill="clear"
                    (click)="toggleCard($event)"
                >
                    <bazis-icon
                        slot="icon-only"
                        [name]="isExpanded ? 'angle-small-up' : 'angle-small-down'"
                    ></bazis-icon>
                </bazis-button>
            </div>
        </bazis-toolbar>
        <ng-content select="header"></ng-content>
    </div>
    <div
        *ngIf="isExpanded"
        class="sl-card-mobile__body"
    >
        <ng-content></ng-content>
    </div>
</div>
