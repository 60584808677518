<ng-container *ngIf="groupData">
    <div class="bazis-vertical-bars">
        <div class="bazis-vertical-bars__guides">
            <div
                class="bazis-vertical-bars__guides__item"
                [style.--top]="'25%'"
            ></div>
            <div
                class="bazis-vertical-bars__guides__item"
                [style.--top]="'50%'"
            ></div>
            <div
                class="bazis-vertical-bars__guides__item"
                [style.--top]="'75%'"
            ></div>
        </div>

        <div class="bazis-vertical-bars__scroll-content">
            <div class="bazis-vertical-bars__wrapper">
                <hr>
                <div
                    *ngFor="let item of groupData"
                    class="bazis-vertical-bars__item"
                    [style.width]="100 / groupData.length + '%'"
                    [class.bazis-vertical-bars__item--active]="
                        selectedValues && selectedValues.indexOf(item.id) > -1
                    "
                    (click)="select.emit(item.id)"
                >
                    <div class="bazis-vertical-bars__item__value">{{
                        item.value | localNumber : '1.0-3'
                    }}</div>
                    <div
                        class="bazis-vertical-bars__item__bar"
                        [style.height]="(item.value / maxForHeight) * 285 + 'px'"
                        [style.min-height]="item.value > 0 ? '3px' : 0"
                    ></div>
                    <div class="bazis-vertical-bars__item__title">
                        {{ item.name || ('analytics.groups.emptyName' | transloco) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container
        *ngIf="groupData?.length === 0"
        [ngTemplateOutlet]="empty"
    >
    </ng-container>
</ng-container>

<ng-template #empty>
    <div *transloco="let t"> {{ t('analytics.groups.empty') }}</div>
</ng-template>
