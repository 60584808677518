<ng-container *ngIf="hasTooltip; else tplNoTooltip">
    <div #reference>
        <bazis-chip
            class="bazis-status"
            [color]="color"
            [size]="size"
            [fill]="fill"
        >
            <span>{{ label }}</span>
        </bazis-chip>
        <bazis-tooltip
            [reference]="reference"
            [eventType]="'hover'"
            placement="top"
            [isStatic]="isStaticTooltip"
            class="bh-popper-z-index"
        >
            {{ label }}
        </bazis-tooltip>
    </div>
</ng-container>

<ng-template #tplNoTooltip>
    <div>
        <bazis-chip
            class="bazis-status"
            [color]="color"
            [size]="size"
            [fill]="fill"
        >
            <span>{{ label }}</span>
        </bazis-chip>
    </div>
</ng-template>
