import { Component, Injectable } from '@angular/core';
import moment from 'moment';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';

import { combineLatest, Observable, of, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { catchError, debounceTime, filter, map, startWith, tap } from 'rxjs/operators';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import {
    AnalyticDashboard,
    AnalyticGroup,
    AnalyticGroupData,
    AnalyticSettings,
} from '@bazis/analytics/shared/models/analytic.types';
import { BazisHorizontalPercentageBarsComponent } from '@bazis/analytics/shared/components/horizontal-percentage-bars/horizontal-percentage-bars.component';
import { BazisAnalyticsStatusCircleDiagramComponent } from '@bazis/analytics/shared/components/status-circle-diagram/status-circle-diagram.component';
import { BazisDateDiagramComponent } from '@bazis/analytics/shared/components/date-diagram/date-diagram.component';
import { FormControl, FormGroup } from '@angular/forms';
import { BazisVerticalBarsComponent } from '@bazis/analytics/shared/components/vertical-bars/vertical-bars.component';

@Injectable({ providedIn: 'root' })
export class BazisAnalyticService {
    date: TemplateObservable<string> = new TemplateObservable(this._today());

    analyticSettings: TemplateObservable<AnalyticSettings> = new TemplateObservable(null);

    selectedSection: TemplateObservable<string> = new TemplateObservable(null);

    selectedGroups: TemplateObservable<{ [index: string]: string[] }> = new TemplateObservable({});

    dashboard: TemplateObservable<AnalyticDashboard> = new TemplateObservable(undefined);

    filters: TemplateObservable<{ [index: string]: any }> = new TemplateObservable({});

    hideDefaultDateFilter = false;

    colors = {
        primary: '#00A2AD',
        secondary: '#43B1F2',
        tertiary: '#817AF9',
        action: '#164982',
        info: '#C4C4C4',
        success: '#78C649',
        warning: '#F6BF36',
        danger: '#ED1A34',
        light: '#f4f5f8',
        medium: '#92949c',
        dark: '#111214',
    };

    reports: {
        buttonSettings: {
            titleKey: string;
            color?: string;
            iconStart?: string;
            iconEnd?: string;
            fill?: 'solid' | 'outline' | 'clear';
            size?: 'xs' | 'small' | 'default' | 'large';
        };
        component: any;
        componentProperties: any;
    }[] = [];

    private _bazisDisplayComponents = {
        horizontalPercentageBar: BazisHorizontalPercentageBarsComponent,
        statusCircleDiagram: BazisAnalyticsStatusCircleDiagramComponent,
        dateDiagram: BazisDateDiagramComponent,
        verticalBars: BazisVerticalBarsComponent,
    };

    appDisplayComponents = {};

    get displayComponents() {
        return {
            ...this._bazisDisplayComponents,
            ...this.appDisplayComponents,
        };
    }

    private _bazisDataTransformers = {
        dates: this.dateTransformer,
    };

    protected appDataTransformers = {};

    dataTransformers = {
        ...this._bazisDataTransformers,
        ...this.appDataTransformers,
    };

    dashboards$ = this.authService.user$.pipe(
        switchMap(() =>
            this.entityService.getEntityList$('analytic/analytics/analytical_dashboard', {
                params: {
                    sort: null,
                },
                limit: 1000,
            }),
        ),
        map((response) => {
            const dashboards = response.list;

            const settings = dashboards.map((dashboard) => {
                const group = dashboard.$snapshot.group_info.reduce((acc, current) => {
                    return {
                        ...acc,
                        [current.field_label]: {
                            id: current.field_label,
                            title: current.name,
                            filterTitle: current.filter_name || current.name,
                            componentType: current.visualization_type,
                            dataType: current?.visualization_settings?.dataType,
                            fixedIndicators: current?.fixed_indicators,
                            componentProperties:
                                current?.visualization_settings?.componentProperties || {},
                            tabTitle: current?.visualization_settings?.tabTitle || current.name,
                        },
                    };
                }, {});
                const config = dashboard.$snapshot.config_groups;
                if (config?.templateGroup) {
                    Object.keys(config.templateGroup).forEach((key) => {
                        if (
                            !config.templateGroup[key].title &&
                            config.templateGroup[key].groupIds?.length > 0
                        ) {
                            config.templateGroup[key].title =
                                group[config.templateGroup[key].groupIds[0]].title;
                        }
                        if (!config.templateGroup[key].mode) {
                            config.templateGroup[key].mode =
                                config.templateGroup[key].groupIds?.length === 1
                                    ? 'default'
                                    : 'tabs';
                        }
                    });
                }

                const allFilterSettings = [
                    ...(dashboard.$snapshot.special_filters || []),
                    ...(dashboard.$snapshot.common_filters || []),
                ].sort((a, b) => a.order - b.order);

                const filterSettingsMap = allFilterSettings.reduce((acc, filter) => {
                    return { ...acc, [filter.id]: filter };
                }, {});

                const filtersForm = new FormGroup({});
                allFilterSettings.forEach((filter) => {
                    filtersForm.addControl(filter.id, new FormControl(null));
                });

                const allSections = dashboard.$snapshot.section_info
                    ? dashboard.$snapshot.section_info.map((v) => {
                          return {
                              ...v,
                              ...v.config,
                          };
                      })
                    : [];

                const enabledSections = allSections.filter((v) => !v.disabled);

                return {
                    id: dashboard.id,
                    label: dashboard.$snapshot.label,
                    title: dashboard.$snapshot.name,
                    availableGroups: dashboard.$snapshot.group_info.map((v) => v.field_label),
                    availableSections: allSections.map((v) => v.field_label),
                    sections: allSections.reduce((acc, current) => {
                        return { ...acc, [current.field_label]: current };
                    }, {}),
                    defaultSection:
                        enabledSections?.length > 0 ? enabledSections[0].field_label : 'NONE',
                    filtersForm,
                    filterSettingsMap,
                    allFilters: allFilterSettings.map((v) => v.id),
                    group,
                    template: config?.template,
                    templateGroup: config?.templateGroup,
                };
            });
            this.dashboard.set(settings.length > 0 ? settings[0] : null);
            this.selectedSection.set(settings.length > 0 ? settings[0].defaultSection : null);
            return settings;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    sections$ = combineLatest([
        this.date.$,
        this.dashboard.$,
        this.selectedGroups.$,
        this.filters.$,
    ]).pipe(
        filter(([date, dashboard, selectedGroups, filters]) => date && !!dashboard),
        debounceTime(0),
        switchMap(([date, dashboard, selectedGroups, filters]) =>
            this._getSectionData(date, dashboard, selectedGroups, filters),
        ),
        map((response) => (response ? response[0] : null)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    groups$ = combineLatest([
        this.date.$,
        this.dashboard.$,
        this.selectedSection.$,
        this.selectedGroups.$,
        this.filters.$,
    ]).pipe(
        filter(
            ([date, dashboard, section, selectedGroups, filters]) =>
                date && !!dashboard && !!section,
        ),
        debounceTime(0),
        switchMap(([date, dashboard, section, selectedGroups, filters]) => {
            return (
                dashboard.availableGroups?.length > 0
                    ? combineLatest(
                          dashboard.availableGroups.map((group: string) =>
                              this._getGroupData(
                                  date,
                                  dashboard,
                                  section,
                                  group,
                                  selectedGroups,
                                  filters,
                              ),
                          ),
                      )
                    : of([])
            ).pipe(
                filter((response) => response.findIndex((v) => !v) === -1),
                withLatestFrom(this.dashboard.$),
                map(([response, dashboard]) => {
                    const result = {};
                    dashboard.availableGroups.forEach((groupId, index) => {
                        result[groupId] =
                            dashboard.group[groupId].dataType &&
                            this.dataTransformers[dashboard.group[groupId].dataType]
                                ? this.dataTransformers[dashboard.group[groupId].dataType](
                                      response[index],
                                  )
                                : response[index];
                    });
                    return result;
                }),
                startWith(undefined),
            );
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    constructor(
        private authService: BazisAuthService,
        private srv: BazisSrvService,
        private entityService: BazisEntityService,
    ) {}

    setAnalyticSettings() {
        this.selectedSection.set(null);
        this.selectedGroups.set({});
        this.date.set(this._today());
    }

    setDate(date) {
        this.date.set(date || this._today());
    }

    setDashboard(dashboard: AnalyticDashboard) {
        //reset filters before setting dashboard

        dashboard.allFilters.forEach((filter) => {
            const control = dashboard.filtersForm.get(filter);
            if (control) control.setValue(dashboard.filterSettingsMap[filter].multiple ? [] : null);
        });

        this.dashboard.set(dashboard);
        this.setFilters(dashboard.filtersForm.value);
        this.setSection(dashboard.defaultSection);
        this.setSelectedGroup(null, null);
        this.setFilters(dashboard.filtersForm.value);
    }

    setSection(section, sectionSettings = null) {
        if (sectionSettings && sectionSettings.disabled) return;
        if (this.selectedSection._ === section) return;
        this.selectedSection.set(section);
    }

    setFilters(filters) {
        this.filters.set(filters);
    }

    setSelectedGroup(value, groupSettings: AnalyticGroup) {
        const selectedGroup = { ...this.selectedGroups._ };

        if (value === null) {
            if (groupSettings === null) {
                this.selectedGroups.set({});
                return;
            }
            delete selectedGroup[groupSettings.id];
            return;
        }

        if (!selectedGroup[groupSettings.id]) {
            selectedGroup[groupSettings.id] = [];
        }

        const index = selectedGroup[groupSettings.id].indexOf(value);
        if (index === -1) {
            if (groupSettings.selectionType === 'radio') {
                selectedGroup[groupSettings.id] = [];
            }
            selectedGroup[groupSettings.id].push(value);
            this.selectedGroups.set(selectedGroup);
            return;
        }

        if (groupSettings.selectionType === 'radio') {
            delete selectedGroup[groupSettings.id];
        } else {
            selectedGroup[groupSettings.id] = selectedGroup[groupSettings.id].filter(
                (v) => v !== value,
            );
        }

        this.selectedGroups.set(selectedGroup);
    }

    private _buildFilters(filters, dashboard) {
        const filterParams: any = {};
        if (!dashboard.allFilters) return {};
        dashboard.allFilters.forEach((filterId) => {
            if (!filters[filterId]) return;
            if (
                filterId === 'daterange' &&
                filters[filterId]?.date_from &&
                filters[filterId]?.date_to
            ) {
                filterParams.dt_from = filters[filterId].date_from;
                filterParams.dt_to = filters[filterId].date_to;
                return;
            }

            if (dashboard.filterSettingsMap[filterId].multiple) {
                if (!filters[filterId].length) return;

                const notNull = filters[filterId].filter((v) => v !== 'null');
                if (notNull.length > 0) {
                    filterParams[`${filterId}__in`] = notNull.join(',');
                }

                if (filters[filterId].indexOf('null') > -1) {
                    filterParams[`${filterId}__isnull`] = true;
                }
            } else {
                if (filters[filterId] !== 'null') {
                    filterParams[filterId] = filters[filterId];
                } else {
                    filterParams[`${filterId}__isnull`] = true;
                }
            }
        });
        return filterParams;
    }

    private _getSectionData(
        date: string,
        dashboard: AnalyticDashboard,
        selectedGroups: any,
        filters: any,
    ) {
        const dtEvent = this.hideDefaultDateFilter ? null : { dt_event: date };
        const filtersParams = this._buildFilters(filters, dashboard);
        return this.srv
            .commonGetRequest$(`analytic/analytics/analytical_dashboard/${dashboard.id}/get_data`, {
                ...dtEvent,
                ...this._selectedGroupsToParams(selectedGroups),
                ...filtersParams,
            })
            .pipe(
                startWith(undefined),
                catchError((e) => of(null)),
            );
    }

    private _getGroupData(
        date: string,
        dashboard: AnalyticDashboard,
        section: string,
        group: string,
        selectedGroups: any,
        filters: any,
    ) {
        const currentSelectedGroups = {
            ...this._selectedGroupsToParams(selectedGroups),
        };
        delete currentSelectedGroups[`${group}__in`];

        const dtEvent = this.hideDefaultDateFilter ? null : { dt_event: date };
        const filtersParams = this._buildFilters(filters, dashboard);
        let params: any = {
            group,
            ...dtEvent,
            ...filtersParams,
            ...currentSelectedGroups,
            limit: 1000,
        };

        if (section && section !== 'NONE') {
            params.section = section;
        }
        if (dashboard.group[group].fixedIndicators) {
            params.section = dashboard.group[group].fixedIndicators;
        }

        return this.srv
            .commonGetRequest$(
                `analytic/analytics/analytical_dashboard/${dashboard.id}/get_data`,
                params,
            )
            .pipe(
                startWith(undefined),
                catchError((e) => of(null)),
            );
    }

    private _selectedGroupsToParams(selectedGroups) {
        return Object.keys(selectedGroups).reduce(
            (acc, current) => ({ ...acc, [`${current}__in`]: selectedGroups[current].join(',') }),
            {},
        );
    }

    private _today() {
        return moment().format('YYYY-MM-DD');
    }

    dateTransformer(data: AnalyticGroupData[]) {
        const dataMap = data.reduce(
            (acc, current) => ({ ...acc, [current.id]: +current.value }),
            {},
        );

        const dataYears = new Set(data.map((v) => +v.id.substring(0, 4)));
        const currentYear = moment().year();
        dataYears.add(currentYear);
        const years = Array.from(dataYears).sort();

        const result = {};
        years.forEach((year: number) => {
            const yearData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reduce(
                (acc, current) => ({ ...acc, [current]: { max: 0 } }),
                {},
            );

            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach((month) => {
                const daysInMonth = moment(`${year}-${month}`, 'YYYY-M').daysInMonth();
                const monthStr = month < 10 ? `0${month}` : month;
                for (let day = 1; day <= daysInMonth; day++) {
                    const dayStr = day < 10 ? `0${day}` : day;
                    yearData[month][day] = dataMap[`${year}-${monthStr}-${dayStr}`] || 0;
                    yearData[month].max =
                        yearData[month][day] > yearData[month].max
                            ? yearData[month][day]
                            : yearData[month].max;
                }
            });

            result[year] = yearData;
        });
        return result;
    }
}
