import { Injectable } from '@angular/core';
import { BazisAnalyticService } from '@bazis/analytics/shared/service/analytic.service';
import { AnalyticsSummaryTableComponent } from '@pages/analytics/components/summary-table/summary-table.component';

@Injectable({ providedIn: 'root' })
export class AnalyticService extends BazisAnalyticService {
    colors = {
        primary: '#00A2AD',
        secondary: '#43B1F2',
        tertiary: '#817AF9',
        action: '#164982',
        info: '#C4C4C4',
        success: '#78C649',
        warning: '#F6BF36',
        danger: '#ED1A34',
        light: '#f4f5f8',
        medium: '#92949c',
        dark: '#111214',
    };

    hideDefaultDateFilter = true;

    reports = [];

    appDisplayComponents = {
        summaryTable: AnalyticsSummaryTableComponent,
    };
}
