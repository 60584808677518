<ng-container *ngIf="groupData">
    <ng-container
        *ngIf="groupData?.length === 0"
        [ngTemplateOutlet]="empty"
    >
    </ng-container>

    <bazis-grid
        *ngIf="groupData?.length > 0"
        class="sl-grid_table"
    >
        <bazis-row
            *ngFor="let row of settings.headers"
            class="sl-row_thead"
        >
            <bazis-col
                *ngFor="let col of row"
                [size]="col.size"
                class="sl-col_th"
            >
                {{ col.title }}
            </bazis-col>
        </bazis-row>
        <bazis-row
            *ngFor="let dataGroup of groupData"
            class="sl-row_tbody"
        >
            <bazis-col
                *ngFor="let valueSettings of settings.values"
                [size]="valueSettings.size"
            >
                <ng-container *ngIf="valueSettings.value_label">
                    <ng-container *ngIf="valueSettings.format === 'number'">
                        {{ dataGroup.values[valueSettings.value_label] | localNumber : '1.0-2' }}
                    </ng-container>
                    <ng-container *ngIf="valueSettings.format !== 'number'">
                        {{ dataGroup.values[valueSettings.value_label] }}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="valueSettings.root_label">
                    <ng-container *ngIf="valueSettings.format === 'number'">
                        {{ dataGroup[valueSettings.root_label] | localNumber : '1.0-2' }}
                    </ng-container>
                    <ng-container *ngIf="valueSettings.format !== 'number'">
                        {{ dataGroup[valueSettings.root_label] }}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="valueSettings.unit">{{ valueSettings.unit }}</ng-container>
            </bazis-col>
        </bazis-row>
    </bazis-grid>
</ng-container>

<ng-template #empty>
    <div *transloco="let t"> {{ t('analytics.groups.empty') }}</div>
</ng-template>
