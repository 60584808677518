import {
    APP_INITIALIZER,
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
    LOCALE_ID,
    ErrorHandler,
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {
    ConfigurationService,
    DISABLE_NOTIFICATIONS_ROLES,
    ROLE,
    ROLE_ACCOUNT_TYPE,
} from '@app/configuration.service';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BazisHeaderInterceptor } from '@bazis/interceptors/header.interceptor';
import { BazisAuthInterceptor } from '@bazis/interceptors/auth.interceptor';
import { BazisHttpErrorsInterceptor } from '@bazis/interceptors/http-errors.interceptor';
import { BazisSignInterceptor } from '@bazis/interceptors/sign.interceptor';

import { SharedModule } from '@shared/shared.module';
import { BazisHttpTimeoutInterceptor } from '@bazis/interceptors/timeout-error.interceptor';
import { BazisConfirmOrgInfoInterceptor } from '@bazis/interceptors/confirm-org-info.interceptor';
import { BazisLocaleService } from '@bazis/shared/services/locale.service';
import { LocalDatePipe } from '@bazis/shared/pipes/local-date.pipe';
import * as Sentry from '@sentry/angular-ivy';
import { isLocal } from '@bazis/utils';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { MpAuthService } from '@shared/services/auth.service';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { TranslocoRootModule } from '@shared/modules/transloco-root.module';
import { BazisConfigurationService } from '@bazis/configuration.service';
import { DISABLE_NOTIFICATIONS_ROLES_DATA } from '@bazis/shared/services/notification.service';
import { BazisChatService } from '@bazis/chat/shared/services/chat.service';
import { ChatService } from '@shared/services/chat.service';
import { BazisAnalyticService } from '@bazis/analytics/shared/service/analytic.service';
import { AnalyticService } from '@pages/analytics/analytic.service';
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEn, 'en');

export function loadConfiguration(configService: ConfigurationService) {
    return () => configService.loadConfiguration();
}

const interceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: BazisHeaderInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: BazisConfirmOrgInfoInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: BazisSignInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: BazisAuthInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: BazisHttpTimeoutInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: BazisHttpErrorsInterceptor,
        multi: true,
    },
];
const sentryProviders = [];
if (!isLocal()) {
    sentryProviders.push({
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
            showDialog: false,
            logErrors: true,
        }),
    });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        TranslocoRootModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isLocal(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
        NgxMaskDirective,
        NgxMaskPipe,
    ],
    providers: [
        { provide: BazisAnalyticService, useExisting: AnalyticService },
        { provide: BazisAuthService, useExisting: MpAuthService },
        { provide: BazisConfigurationService, useExisting: ConfigurationService },
        { provide: BazisChatService, useExisting: ChatService },
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfiguration,
            deps: [ConfigurationService],
            multi: true,
        },
        Title,
        {
            provide: LOCALE_ID,
            deps: [BazisLocaleService],
            useFactory: (localeService) => localeService.locale,
        },
        ...interceptorProviders,
        ...sentryProviders,
        LocalDatePipe,
        provideEnvironmentNgxMask(),
        { provide: DISABLE_NOTIFICATIONS_ROLES_DATA, useValue: DISABLE_NOTIFICATIONS_ROLES },
        { provide: 'ROLE', useValue: ROLE },
        { provide: 'ROLE_ACCOUNT_TYPE', useValue: ROLE_ACCOUNT_TYPE },
    ],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}
